import React, { useState, useEffect } from 'react';
import './Header.css';

const Header = () => {
  const [selectedLink, setSelectedLink] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMenuOpen]);

  const handleLinkClick = (link) => {
    setSelectedLink(link);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="header">
      <nav className="navbar">
        {/* Logo y enlace al muro */}
        <div className="navbar-logo">
          <a href="/">
            <img src='assets/images/logo-alien-realty-2.png' alt="Logo" className="logo" />
          </a>
          <div
            className="floating-icon-container"
            onClick={() => window.location.href = 'https://alienrealtyhub-e4bdecd9adcfd3fb.centralus-01.azurewebsites.net/'}
          >
            <img src="/assets/images/nave2.png" alt="Nave Espacial" className="floating-icon" />
            <div className="messageBox">🚀 Visita nuestro muro</div>
          </div>
        </div>

        {/* Menú de navegación */}
        <div className={`navbar-center ${isMenuOpen ? 'active' : ''}`}>
          <ul className="navbar-links">
            {['hero', 'modules', 'download', 'explore', 'contact'].map((link) => (
              <li key={link}>
                <a
                  href={`#${link}`}
                  className={selectedLink === link ? 'selected' : ''}
                  onClick={() => handleLinkClick(link)}
                >
                  {link === 'hero' ? 'Acerca de' :
                   link === 'modules' ? 'Módulos' :
                   link === 'download' ? 'Descargar App' :
                   link === 'explore' ? 'Explora' :
                   'Contáctanos'}
                </a>
              </li>
            ))}
            {isMenuOpen && (
              <>
                <li>
                  <a href="https://alienrealtyweb-admin-cbhmaugqcdbkcnf3.centralus-01.azurewebsites.net/login" className="btn btn-primary">Iniciar Sesión</a>
                </li>
                <li>
                  <a href="https://alienrealtyweb-admin-cbhmaugqcdbkcnf3.centralus-01.azurewebsites.net/login" className="btn btn-secondary">Registrarse</a>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* Botones de autenticación */}
        <div className="navbar-buttons">
          <a href="https://alienrealtyweb-admin-cbhmaugqcdbkcnf3.centralus-01.azurewebsites.net/login" className="btn btn-primary">Iniciar Sesión</a>
          <a href="https://alienrealtyweb-admin-cbhmaugqcdbkcnf3.centralus-01.azurewebsites.net/login" className="btn btn-secondary">Registrarse</a>
        </div>

        {/* Toggle del menú móvil */}
        <div className="navbar-toggle" onClick={toggleMenu}>
          <div className={`line ${isMenuOpen ? 'line1' : ''}`}></div>
          <div className={`line ${isMenuOpen ? 'line2' : ''}`}></div>
          <div className={`line ${isMenuOpen ? 'line3' : ''}`}></div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
